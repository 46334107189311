<template>
  <div id="netTransmit-container" class="yxDetail-container">
    <div>
      <navigation></navigation>
      <div class="detail-desc-box">
        <div class="detail-desc-title">
          {{ dataForm.messageTitle }}
        </div>
        <div id="content" class="detail-desc">
        </div>
      </div>
      <div class="detail-info">
        <div class="detail-info-item">
          <a :href="dataForm.filesUrl" target="_blank">点击下载附件</a>
        </div>
        <div style="width: 100%;display: flex;justify-content: space-between;align-items: center">
          <span></span>
          <div v-if="userInfo.id">
            <div class="sign-btn" @click="readMessage" v-if="dataForm.wasRead == 0">确认已读</div>
            <div class="sign-btn-dis" v-else>已阅读</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import navigation from '@/components/navigation'
  export default {
    name: "detail",
    components: {
      navigation
    },
    data() {
      return {
        dataForm: {
          filesUrl: '',
          messageTitle: '',
          messageContent: '',
          wasRead: '0'
        },
        userInfo: {
          id: ''
        }
      }
    },
    async mounted() {
      try {
        let r = await this.$api.home.getUserInfo({}, false)
        if (r.code == 200) {
          Object.assign(this.userInfo, r.result)
        }
      } catch (e) {
        
      }
      this.selectWebMessage()
    },
    methods: {
      readMessage() {
        this.$api.home.webMessageUpdate({
          id: this.$route.params.id,
          wasRead: 1,
          userId: this.userInfo.id
        }).then(res => {
          this.selectWebMessage()
        })
      },
      selectWebMessage() {
        this.$api.home.selectWebMessage({
          id: this.$route.params.id
        }).then(res => {
          let {code, result} = res
          if (code == 200) {
            let { filesUrl, messageTitle, messageContent, wasRead } = result.rows[0]
            this.dataForm = {
              filesUrl, messageTitle, messageContent, wasRead
            }
            document.getElementById('content').innerHTML = messageContent
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
#netTransmit-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  min-height: calc(100vh - 449px);
  .detail-desc-box {
    padding: 20px 80px;
    border-bottom: 1px solid #eee;
    background-color: #fff;
  }
  .detail-desc-title {
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-weight: bolder;
    padding: 10px 0;
  }
  .detail-desc {
    font-size: 18px;
    min-height: 300px;
    line-height: 40px;
    padding: 0 50px;
    font-family: fangsong;
  }
  .detail-info {
    padding: 20px;
    background-color: #fff;
    text-align: left;
  }
  .detail-info-item {
    padding: 10px 0;
    font-size: 16px;
  }
  .sign-btn {
    width: 174px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    color: #fff;
    background-color: #409EFF;
    border-radius: 56px;
    cursor: pointer;
  }
  .sign-btn:hover {
    background-color: #3CD9FF;
  }
  .sign-btn-dis {
    width: 174px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    color: #fff;
    background-color: #D8D8D8;
    border-radius: 56px;
  }
}
@font-face {
  font-family: fangsong;
  src: url("../../assets/ttf/fangsong_GB2312.ttf");
}
.detail-desc {
  text-indent: 2em;
  font-family: fangsong;
}
</style>