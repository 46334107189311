<template>
  <div class="netTransmit-container">
    <navigation></navigation>
    <el-breadcrumb style="background-color: #fff;padding: 30px 0 30px 30px;border-bottom: 2px solid #2C9FE8" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><span style="color: #767676;cursor: pointer;" @click="$router.push({name: 'home'})">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="color: #00BCEA;">网络发信</span></el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-box">
      <div class="table-header table-cell">
        <div class="table-cell-row table-cell-row1">
          标题
        </div>
        <div class="table-cell-row table-cell-row2">
          <div style="width: 60px">科室</div>
          <el-select v-model="department" @change="departmentFilterChange" clearable placeholder="请选择科室">
            <el-option label="高中部" value="高中部"></el-option>
            <el-option label="课程部" value="课程部"></el-option>
            <el-option label="小学部" value="小学部"></el-option>
            <el-option label="德育部" value="德育部"></el-option>
            <el-option label="体音美部" value="体音美部"></el-option>
            <el-option label="师训部" value="师训部"></el-option>
            <el-option label="干训部" value="干训部"></el-option>
            <el-option label="教育学会" value="教育学会"></el-option>
            <el-option label="初中部" value="初中部"></el-option>
            <el-option label="教科研部" value="教科研部"></el-option>
            <el-option label="宣教部" value="宣教部"></el-option>
            <el-option label="办公室" value="办公室"></el-option>
            <el-option label="学前部" value="学前部"></el-option>
            <el-option label="教务处" value="教务处"></el-option>
            <el-option label="财务室" value="财务室"></el-option>
            <el-option label="技活职成部" value="技活职成部"></el-option>
            <el-option label="总务处" value="总务处"></el-option>
            <el-option label="工会委员会" value="工会委员会"></el-option>
          </el-select>
        </div>
        <div class="table-cell-row table-cell-row3">
          阅读量
        </div>
        <div class="table-cell-row table-cell-row4">
          <div style="width: 60px">时间</div>
          <el-date-picker
                  v-model="createTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  @change="selectWebMessage"
                  style="width: 200px;margin-right: 30px"
                  placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div style="min-height: 600px">
        <div v-if="dataList.length > 0" class="table-cell"  @click="toDetail(item)" v-for="(item, index) in dataList" :key="index">
          <div class="table-cell-row table-cell-row1">
            {{ item.messageTitle }}
          </div>
          <div class="table-cell-row table-cell-row2">
            {{ item.department }}
          </div>
          <div class="table-cell-row table-cell-row3">
            {{ item.readingVolume }}
          </div>
          <div class="table-cell-row table-cell-row4">
            {{ item.createTime }}
          </div>
        </div>
        <div v-if="dataList.length < 1" style="width: 100%;text-align: center;color: #999;padding-top: 100px">
          暂无数据～
        </div>
      </div>
    </div>
    <div style="width: 100%; text-align: center; padding: 15px 0;background-color: #fff;">
      <el-pagination
              layout="prev, pager, next"
              @current-change="pageChange"
              :page-size="page.pageSize"
              :current-page="page.pageNum"
              :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import navigation from '@/components/navigation'
  export default {
    name: "index",
    components: {
      navigation,
    },
    data() {
      return {
        dataList: [],
        createTime: '',
        department: '',
        page: {
          pageSize: 20,
          pageNum: 1,
          total: 0
        },
      }
    },
    mounted() {
      this.selectWebMessage()
    },
    methods: {
      departmentFilterChange(e) {
        this.department = e
        this.selectWebMessage()
      },
      pageChange(pageNum) {
        this.page.pageNum = pageNum
        this.selectWebMessage()
      },
      selectWebMessage() {
        let { pageSize, pageNum } = this.page
        let { department, createTime } = this
        this.$api.home.selectWebMessage({
          offset: (parseInt(pageNum) - 1) * pageSize,
          limit: pageSize,
          department,
          createTime,
          // userId: this.userInfo.id
        }).then(res => {
          let { code, result } = res
          if (code == 200) {
            this.dataList=result.rows
            this.page.total = res.result.total ||[]
          }
        })
      },
      toDetail(item) {
        this.$router.push({
          name: 'netTransmitDetail',
          params: {
            id: item.id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.netTransmit-container {
  width: 1200px;
  margin: auto auto 50px;
  .table-box {
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
  }
  .table-cell {
    display: flex;
  }
  .table-cell-row1 {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .table-cell-row2 {
    width: 300px;
    display: flex;
    justify-content: space-between;
  }
  .table-cell-row3 {
    width: 100px;
  }
  .table-cell-row4 {
    width: 330px;
    display: flex;
    justify-content: space-between;
  }
  .table-cell-row {
    height: 65px;
    line-height: 65px;
    padding: 0 20px;
    border-bottom: 1px solid #eee;
  }
}
</style>
